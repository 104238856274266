import React from "react"

//Data Hooks
import { useFacilitiesQuery } from "../hooks/useFacilitiesQuery"

//Components
import Layout from "../components/layout/Layout"
import FullWidthBlock from "../components/content/FullWidthBlock"
import TwoColBlock from "../components/content/TwoColBlock"

export default function Facilities() {
  //WP Data
  const data = useFacilitiesQuery()

  return (
    <Layout>
      <div className="wrapper sub_page">
        <FullWidthBlock
          title="Providing a personal service to all of our customers."
          content="Chandlery, boat parts, hot and cold drinks, and confectionary are available in our on-site shop, but theres more to our service than that!"
          fancyBgText="Facilities"
        />
      </div>

      {data.wpPage.contentBlocks.block.map(({ title, content, image }) => (
        <TwoColBlock
          headStyle="special"
          title={title}
          content={content}
          key={title}
          image={image.mediaItemUrl}
        />
      ))}

      <div className="wrapper">
        <FullWidthBlock
          title="Moorings &amp; Miscellaneous."
          content="Moorings are available for boat owners, as well as specific work required, stock items, or specialist supplies, please get in touch and we will do our best to help!"
          linkTo="/contact/"
          linkText="Get in touch"
          headStyle="special"
        />
      </div>
    </Layout>
  )
}

export function Head() {
  return (
    <>
      <title>Facilities | Lower Park Marina</title>
      <meta
        name="description"
        content="Check out the facilities we have available at our marina."
      />
    </>
  )
}
