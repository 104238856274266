import { useStaticQuery, graphql } from "gatsby";

export const useFacilitiesQuery = () => {
    const data = useStaticQuery(graphql`
    {
      wpPage(id: {eq: "cG9zdDozMg=="}) {
        id
        contentBlocks {
          block {
            title
            content
            image {
              mediaItemUrl
            }
          }
        }
      }
    }
    `)

    return data
}
